.kanit-thin {
  font-family: "Kanit", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.kanit-extralight {
  font-family: "Kanit", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.kanit-light {
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.kanit-regular {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.kanit-medium {
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.kanit-semibold {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.kanit-bold {
  font-family: "Kanit", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.kanit-extrabold {
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.kanit-black {
  font-family: "Kanit", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.kanit-thin-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.kanit-extralight-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.kanit-light-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.kanit-regular-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.kanit-medium-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.kanit-semibold-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.kanit-bold-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.kanit-extrabold-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.kanit-black-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 900;
  font-style: italic;
}
 
@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

.whitespace-pre-wrap {
  white-space: pre-wrap; /* Allows text to wrap and respects new lines */
  overflow: hidden; /* Hides overflow text */
  position: relative; /* For animation positioning */
}

.overflow-hidden {
  overflow: hidden; /* Ensures no text overflow outside of container */
}
